import React from 'react';
import { graphql } from 'gatsby';
import { mergePrismicPreviewData } from 'gatsby-source-prismic';
import {
  Layout,
  Slice,
  CategoryGrid,
  PostGrid,
  BlogPostSideBar,
  BlogPostHeader,
  TableOfContents,
  ScrollBar,
  Image,
} from 'components';
import { TextBlock } from 'slices';
import './blog_post.scss';

const IS_BROWSER = typeof window !== 'undefined';

export const BlogPost = ({ location, data: staticData = {} }) => {
  // data
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__;
  const data = mergePrismicPreviewData({ staticData, previewData });
  const { blogPost = {}, relatedPosts = {}, recentPosts = {}, footerCta = {} } = data;

  const {
    data: pageData = {},
    dataString,
    uid,
    first_publication_date: publishedDate,
    last_publication_date: updatedDate,
  } = blogPost;
  const { nodes: filteredPosts = [] } = relatedPosts;
  const { nodes: newPosts = [] } = recentPosts;
  const hasNewPosts = newPosts.length >= 3;
  const { data: footerData = {} } = footerCta;
  const { body = [] } = footerData;
  const footerCtaData = body[0];

  const {
    body: sliceData,
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
    title,
    hero_image: heroImage,
    category,
    type,
    timestamp,
    author,
  } = pageData;

  const formatType = type === 'short-form' ? 'short-form' : 'long-form';
  const anchorPoints = [];

  sliceData.forEach(slice => {
    const { slice_type: sliceType } = slice;
    if (sliceType === 'anchor_point') {
      if (!slice?.primary?.exclude_tac) {
        anchorPoints.push(slice?.primary?.anchor_title?.text);
      }
    }
  });

  // seo
  const seo = {
    title: metaTitle.text,
    desc: metaDesc.text,
    banner: ogImage && ogImage.url,
    article: true,
    publishedDate,
    updatedDate,
  };

  const hasRelatedPosts = filteredPosts.length > 0 ? 'has-sidebar' : '';

  // render
  return (
    <Layout location={location} seo={seo}>
      <div className="blog-post">
        <div className="wrapper">
          <BlogPostHeader
            title={title}
            category={category}
            timestamp={timestamp}
            author={author}
            dataString={dataString}
          />
          <section className="blog-post-image">
            <Image image={heroImage} className="blog-post-hero-image" />
          </section>
          <div className={`blog-post-content-container ${formatType} ${hasRelatedPosts}`}>
            {type === 'long-form' && <TableOfContents anchorPoints={anchorPoints} />}
            <div className="blog-post-slice-content">
              {sliceData.map(slice => (
                <Slice key={slice.id} data={{ ...slice, uid }} location={location} />
              ))}
            </div>
            {type === 'short-form' && filteredPosts.length > 0 && (
              <BlogPostSideBar filteredPosts={filteredPosts} location={location} title={title} />
            )}
          </div>
        </div>
        {/* <CategoryGrid /> */}
        {/* {hasNewPosts && <PostGrid posts={newPosts} className="recent-posts" title="Recent Posts" viewAll />} */}
        {footerCtaData && <TextBlock data={footerCtaData} />}
      </div>
    </Layout>
  );
};

export default BlogPost;

export const blogPostQuery = graphql`
  query BlogPostBySlug($uid: String!, $category: String) {
    blogPost: prismicBlogPost(uid: { eq: $uid }) {
      id
      uid
      prismicId
      dataString
      first_publication_date
      last_publication_date
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url
        }
        title {
          text
          html
        }
        hero_image {
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        type
        category {
          id
          uid
        }
        timestamp(formatString: "Do, MMM yyyy")
        author {
          text
          html
        }
        body {
          ...BlogPostWysiwyg
          ...BlogPostAnchorPoint
          ...BlogPostBreakoutBox
          ...BlogPostImage
          ...BlogPostBlockQuote
          ...VideoBlogPostFragment
        }
      }
    }
    relatedPosts: allPrismicBlogPost(
      filter: { data: { category: { uid: { eq: $category } } }, uid: { ne: $uid } }
      limit: 3
    ) {
      nodes {
        uid
        id
        data {
          category {
            uid
          }
          title {
            html
            text
          }
          hero_image {
            url
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    recentPosts: allPrismicBlogPost(
      limit: 3
      filter: { uid: { ne: $uid } }
      sort: { order: ASC, fields: last_publication_date }
    ) {
      nodes {
        uid
        data {
          category {
            uid
          }
          hero_image {
            url
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          title {
            text
            html
          }
        }
      }
    }
    footerCta: prismicSettings {
      data {
        body {
          ... on PrismicSettingsBodyTextBlock {
            id
            slice_type
            primary {
              background_colour
              content {
                html
                text
              }
              cta_link {
                url
              }
              cta_text {
                html
                text
              }
              show_logo_background
              title {
                html
                text
              }
            }
          }
        }
      }
    }
  }
`;
